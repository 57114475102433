import React, { useState } from 'react'
import { Button } from '@theme-ui/components'
import Modal from '../../Modals/v1/Modal'
export default function PopUp(props) {
  const {
    variant,
    //  titles,
    //  title,
    //  images,
    ctaName,
    //  ctaLinks
  } = props
  const [modalActive, setToggleModal] = useState(false)

  const toggleModal = () => setToggleModal(!modalActive)

  return (
    <>
      <Button
        variant={`${variant}.popupButton`}
        onClick={() => {
          setToggleModal(!modalActive)
        }}>
        {ctaName}
      </Button>

      {modalActive ? <Modal {...props} toggleModal={toggleModal} /> : ''}
    </>
  )
}

// pop ups come with an activation button and import a modal to use
